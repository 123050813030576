<div class="AboutPage page page-entering" ref:page on:animationend="enableScroll()">
  <header class="header">
    <a href="/" on:click="goto('/', event)" class="btn-back">{{{$dictionary.about.backBtn}}}</a>
  </header>
  <div class="container">
    <div class="title">{{{$dictionary.about.titleAbout}}}</div>
    <p>{{{$dictionary.about.about1}}}</p>
    <p>{{{$dictionary.about.about2}}}</p>

    <div class="title">{{{$dictionary.about.titlePeercoin}}}</div>
    <p>{{{$dictionary.about.peercoin1}}}</p>

    <div class="title">{{{$dictionary.about.titleDisclaimer}}}</div>
    <p>{{{$dictionary.about.disclaimer1}}}</p>

    <div class="title">{{{$dictionary.about.titleCredits}}}</div>
    <p>{{{$dictionary.about.credits1}}}</p>
    <p>{{{$dictionary.about.credits2}}}</p>
    <p>{{{$dictionary.about.credits3}}}</p>
  </div>
</div>

<script>
  import roadtrip from 'roadtrip';

  export default {
    methods: {
      goto(path, e) {
        if (e) {
          e.preventDefault();
        }
        roadtrip.goto(path);
      },
      enableScroll() {
        document.body.style = '';
        this.refs.page.classList.remove('page-entering');
      }
    },
    helpers: {
      timeAlive() {
        const PPC_GENESIS = 1344794258295;
        const NOW = +new Date();
        const YEAR = 31536000000;

        return Math.round((NOW - PPC_GENESIS) / YEAR);
      }
    }
  }
</script>