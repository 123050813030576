<div class="IndexPage page page-entering">
  <div class="container">
    <Icon name="logo" />
    <h1 class="title">{{$dictionary.index.title}}</h1>
    <h2 class="subtitle">{{$dictionary.index.subtitle}}</h2>
    <a href="#" on:click="goto('/generator')" class="btn-start">
      {{$dictionary.index.start}}
    </a>

    <div class="options-panel">
      <div class="option">
        <label>{{$dictionary.index.options.numberOfWallets}}</label>
        <input class="field" type="number" min="1" max="1000" value="1" on:input="handleBulk(event.target.value)">
      </div>
      <div class="option">
        <label>{{$dictionary.index.options.language}}</label>
        <select on:change="handleLanguage(event)">
          {{#each allowedLanguages as lang}}
            {{#if lang === currentLang}}
              <option value="{{lang}}" selected>{{lang}}</option>
            {{/if}}
            {{#if lang !== currentLang}}
              <option value="{{lang}}">{{lang}}</option>
            {{/if}}
          {{/each}}
        </select>
      </div>
    </div>
  </div>
  <footer class="footer">
    <a href="#" on:click="goto('/about')" class="footer-link">{{$dictionary.index.aboutLink}}</a>
    <a href="https://peercoin.net/" target="_blank" class="footer-link">peercoin.net</a>
  </footer>
</div>

<script>
  import roadtrip from 'roadtrip';
  import Icon from '../../components/icon/icon.component.svelte';

  export default {
    data() {
      return {
        allowedLanguages: window['allowedLanguages'],
        currentLang: localStorage.getItem('ppc-user-language')
      }
    },
    methods: {
      goto(path, e) {
        if (e) {
          e.preventDefault();
        }
        roadtrip.goto(path);
      },
      handleBulk(number) {
        window.store.set({
          numberOfWallets: number
        });
      },
      handleLanguage(e) {
        const lang = e.target.value;
        localStorage.setItem('ppc-user-language', lang);
        window.location.reload();
      }
    },
    components: {
      Icon
    }
  }
</script>