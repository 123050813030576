<div class="WalletPage page page-entering" ref:page>
  <h1 class="title">
    {{#if wallets.length === 1}}
      <div class="checkmark"><Icon name="check" /></div> {{$dictionary.wallet.title}}
    {{/if}}
    {{#if wallets.length > 1}}
      <div class="checkmark"><Icon name="check" /></div> {{wallets.length}} {{$dictionary.wallet.titlePlural}}
    {{/if}}
  </h1>

  {{#if wallets.length > 1}}
    <MultipleWallets wallets="{{wallets}}" />
  {{/if}}
  {{#if wallets.length === 1}}
    <h1 class="print-only print-title">{{$dictionary.wallet.titlePrint}}</h1>
    <PaperWallet publicAddress="{{publicAddress}}" privateKey="{{privateKey}}" publicQRCode="{{publicQRCode}}" privateQRCode="{{privateQRCode}}" />
    <img src="{{publicQRCode}}" alt="Public Address QR Code" class="qr-code">
    <div class="wallet-details">
      <div class="share-btn" on:click="openShareMenu()">
        <Icon name="share" />
      </div>
      <div class="section">
        <div class="section-title">{{$dictionary.wallet.address}}</div>
        <div class="section-value selectable">{{publicAddress}}</div>
      </div>
      <div class="section">
        <div class="section-title">{{$dictionary.wallet.privateKey}}
          <button class="btn btn-section" on:click="togglePrivateKeyVisibility()">{{(isPrivateKeyVisible) ? 'Hide' : 'Show'}}</button>
        </div>
        {{#if !isPrivateKeyVisible}}
          <div class="section-value grey">{{$dictionary.wallet.makeSure}}</div>
        {{/if}}
        {{#if isPrivateKeyVisible}}
          <img src="{{privateQRCode}}" alt="{{$dictionary.wallet.privateQRCode}}" class="qr-code-private">
          <div class="section-value selectable">{{privateKey}}</div>
        {{/if}}
      </div>
    </div>
  {{/if}}

  <button class="btn clear-data" on:click="reset()">{{$dictionary.wallet.clearBtn}}</button>

  <nav class="share-menu {{(isShareMenuOpen) ? 'active' : ''}}">
    <div class="overlay" on:click="closeShareMenu()"></div>
    <ul class="menu">
      <li><a href="{{actionDownloadTextFile()}}" download="peercoin_wallet.txt">{{$dictionary.wallet.menu.saveText}}</a></li>
      <li><a href="#" on:click="actionCopyToClipboard(event)">{{{$dictionary.wallet.menu.copyAddress}}}</a></li>
      <li><a href="mailto:your-address-here@email.com?body={{actionGetMailBody()}}">{{$dictionary.wallet.menu.sendEmail}}</a></li>
      <li><a href="{{actionDownloadJSONFile()}}" download="peercoin_wallet.json">{{$dictionary.wallet.menu.saveJSON}}</a></li>
      <li><a href="#" on:click="actionPaperWallet(event)">{{$dictionary.wallet.menu.paperWallet}}</a></li>
    </ul>
  </nav>
</div>

<script>
  import roadtrip from 'roadtrip';
  import { Store } from 'svelte/store.umd.js';
  import Icon from '../../components/icon/icon.component.svelte';
  import MultipleWallets from '../../components/multiple-wallets/multiple-wallets.component.svelte';
  import PaperWallet from '../../components/paperwallet/paperwallet.component.svelte';

  export default {
    data() {
      const wallets = window.store.get('wallets');
      return {
        wallets: wallets,
        publicAddress: wallets[0].publicAddress,
        privateKey: wallets[0].privateKey,
        privateQRCode: wallets[0].privateQRCode,
        publicQRCode: wallets[0].publicQRCode,
        isPrivateKeyVisible: false,
        isShareMenuOpen: false
      }
    },
    methods: {
      goto(path, e) {
        if (e) {
          e.preventDefault();
        }
        roadtrip.goto(path);
      },
      togglePrivateKeyVisibility() {
        this.set({isPrivateKeyVisible: !this.get('isPrivateKeyVisible')})
      },
      reset() {
        const dictionary = window.store.get('dictionary');
        delete window.store;
        window.store = null;
        window.store = new Store({
          wallets: [],
          numberOfWallets: 1,
          dictionary
        });
        this.goto('/');
      },
      openShareMenu() {
        this.set({isShareMenuOpen: true});
      },
      closeShareMenu() {
        this.set({isShareMenuOpen: false});
      },
      actionPaperWallet(e) {
        e.preventDefault();
        window.print();
      },
      actionCopyToClipboard(e) {
        e.preventDefault();

        const textArea = document.createElement("textarea");
        const keys = `${this.get('publicAddress')} /// ${this.get('privateKey')}`;
        
        textArea.classList.add('clipboard', 'selectable');
        textArea.value = keys;

        document.body.appendChild(textArea);
        textArea.select();

        try {
          document.execCommand('copy');
        } catch (err) {
          console.error('Oops, unable to copy');
        }

        document.body.removeChild(textArea);
      },
      actionPrint(e) {
        e.preventDefault();
        window.print();
      }
    },
    helpers: {
      actionDownloadTextFile() {
        const publicAddress = window.store.get('wallets')[0].publicAddress;
        const privateKey = window.store.get('wallets')[0].privateKey;

        const text = `
        Date issued: ${new Date()}
        
        Public Address: ${publicAddress}
        
        Private Key (DO NOT SHARE): ${privateKey}
        `;

        return URL.createObjectURL(new Blob([text], {type: 'text/plain'}));
      },
      actionGetMailBody() {
        const publicAddress = window.store.get('wallets')[0].publicAddress;
        const privateKey = window.store.get('wallets')[0].privateKey;

        const text = `
        Date issued: ${new Date()}

        Public Address: ${publicAddress}
        
        Private Key (DO NOT SHARE): ${privateKey}
        `;

        return text;
      },
      actionDownloadJSONFile() {
        const publicAddress = window.store.get('wallets')[0].publicAddress;
        const privateKey = window.store.get('wallets')[0].privateKey;

        const data = {
          dateIssued: new Date(),
          publicAddress: publicAddress,
          privateKey: privateKey
        };

        return URL.createObjectURL(new Blob([JSON.stringify(data)], {type: 'application/json'}));
      }
    },
    components: {
      Icon,
      MultipleWallets,
      PaperWallet
    }
  }
</script>