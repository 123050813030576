{{#if name === 'check'}}
<!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="2750.079 60.362 17.916 12.115" id="icon-check"><g transform="translate(2670)"><path class="a" d="M0,0H10.168V1.965H0Z" transform="translate(87.269 72.477) rotate(-135)"/><path class="a" d="M0,0H15.169V1.965H0Z" transform="translate(85.88 71.088) rotate(-45)"/></g></svg> -->
<img src="/img/check.svg" width="17">
{{/if}}

{{#if name === 'hand'}}
<!-- <svg version="1.1" id="icon-hand" class="instruction-hand" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 116.1 124.3" style="enable-background:new 0 0 116.1 124.3;" xml:space="preserve">
<g transform="matrix(0.89, 0.45, -0.45, 0.89, -295.27, -514.88)">
	<g id="Path_2-2">
		<path class="st0" d="M531.4,347.8c-0.3,0.2-0.7,0.3-1.1,0.5c-1.4,0.5-4,1.1-7.9,1.1c-2.8-0.1-6.3-0.6-7.5-0.8
			c-0.2,0-0.3,0-0.3-0.1c-1.3-0.2-2.2-1.5-2-2.8c0.2-1.3,1.5-2.2,2.8-2l0.1,0c0.3,0.1,2.3,0.4,3.9,0.5c0,0,0.1,0,0.1,0
			c0,0,0-0.1,0-0.1l-14.3-10.4c-0.5-0.4-0.9-1-1-1.6c-0.1-0.7,0.1-1.3,0.4-1.8c0.4-0.5,1-0.9,1.6-1c0.7-0.1,1.3,0.1,1.8,0.4l6.9,5
			c-0.3-0.9-0.2-1.9,0.5-2.6c0.8-0.8,2.2-1,3.2-0.3c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.7,0.2-1.3,0.7-1.8c0.9-0.8,2.2-1,3.2-0.3
			c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.7,0.2-1.3,0.7-1.8l0,0c0.9-0.9,2.3-1,3.4-0.3l3.4,2.5c3,2.2,5.3,5.2,6.6,8.8l0.6,1.8
			c0.4,1,0.2,2.2-0.4,3l-3.4,4.7C532.6,347.1,532.1,347.5,531.4,347.8z M514.6,348.2c0,0,0.1,0,0.4,0.1c1.2,0.2,4.7,0.7,7.4,0.8
			c3.8,0.1,6.3-0.5,7.8-1.1c1.1-0.4,2.1-1,2.5-1.5l3.4-4.7c0.6-0.8,0.7-1.8,0.4-2.7l-0.6-1.8c-1.2-3.5-3.5-6.4-6.4-8.6l-3.4-2.5
			c-0.9-0.6-2.1-0.5-2.9,0.2c-0.4,0.4-0.6,0.9-0.6,1.5c0,0.6,0.2,1.1,0.6,1.5c0.1,0.1,0.1,0.1,0.2,0.2l0,0l-0.2,0.3c0,0,0,0,0,0
			l-0.9-0.7c-0.9-0.6-2.1-0.6-2.8,0.2c-0.4,0.4-0.6,0.9-0.6,1.5c0,0.6,0.2,1.1,0.6,1.5c0.1,0.1,0.1,0.1,0.2,0.2l0,0l-0.2,0.3
			c0,0,0,0,0,0l-0.9-0.7c-0.9-0.6-2.1-0.6-2.8,0.2c-0.4,0.4-0.6,0.9-0.6,1.5c0,0.6,0.2,1.1,0.6,1.5c0.1,0.1,0.1,0.1,0.2,0.2l0,0
			l-0.2,0.3c0,0,0,0,0,0l-7.9-5.8c-0.5-0.3-1-0.5-1.6-0.4c-0.6,0.1-1,0.4-1.4,0.8c-0.3,0.5-0.5,1-0.4,1.6c0.1,0.6,0.4,1,0.8,1.4
			l14.3,10.4c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.4,0.2c-1-0.1-3.4-0.4-3.9-0.6l0,0
			c-1.1-0.2-2.2,0.6-2.4,1.7C512.8,346.9,513.5,348,514.6,348.2L514.6,348.2z"/>
	</g>
	<path id="Path_1" class="st1" d="M507.8,337.5l-3.8-2.8c-1.7-1.2-2.1-3.6-0.8-5.4s3.6-2.1,5.4-0.8c0,0,0,0,0,0l3.8,2.8
		c-0.1-3.4-3-6.1-6.4-5.9c-3.4,0.1-6.1,3-5.9,6.4s3,6.1,6.4,5.9C506.9,337.7,507.4,337.7,507.8,337.5z"/>
		<animateMotion dur="6s" repeatCount="indefinite" path="M18.5,12.2c8.6,0,17.4-0.9,24.5,6.1c8,9,2,21,1.7,31.4c-0.5,2.9-0.8,5.7-0.7,8.4c0.2,5.4,2.1,10.4,7.7,14.6
	c2,1.6,4.3,3,6.8,4.2c2.8,1.4,5.9,2.5,9.2,3.3c8.6,2.2,16.8-4.6,16.4-13.4c-0.7-15.6-3.9-38.5-17-46.4C50.5,10.4,28.9,2.6,17.6,6.6
	C14.6,7.7,15.3,12.2,18.5,12.2z">
      </animateMotion>
</g>
<path d="M18.5,12.2c8.6,0,17.4-0.9,24.5,6.1c8,9,2,21,1.7,31.4c-0.5,2.9-0.8,5.7-0.7,8.4c0.2,5.4,2.1,10.4,7.7,14.6
	c2,1.6,4.3,3,6.8,4.2c2.8,1.4,5.9,2.5,9.2,3.3c8.6,2.2,16.8-4.6,16.4-13.4c-0.7-15.6-3.9-38.5-17-46.4C50.5,10.4,28.9,2.6,17.6,6.6
	C14.6,7.7,15.3,12.2,18.5,12.2z" class="st2" />
</svg> -->
<img src="/img/hand.svg" class="icon instruction-hand">
{{/if}}

{{#if name === 'logo'}}
<!-- <svg width="50%" id="icon-logo" class="logo" viewBox="0 0 211.5 52">
<g transform="translate(-6832 -2761)">
	<path class="st0" d="M6855.9,2813c-0.6,0-1.1,0.1-1.7,0.1c-12.3,0-22.3-10-22.3-22.3c0-0.1,0-0.2,0-0.3v-0.3l0,0v-23.6l0,0
		c7.5-0.1,25.9,1.5,36,15.6c4.4,6.2,5.4,14.2,2.6,21.2l0,0c-1.3,3.4-3.9,6.2-7.2,7.7c4.6-17.8-4.3-32.4-23.2-37.5
		C6853.7,2778.9,6864.9,2798.3,6855.9,2813z"/>
	<g class="st1">
		<path class="st2" d="M6910.8,2790.1c0,5.4-3.9,9.3-8.9,9.3c-2.5,0-4.3-1-5.5-2.6v9.5h-3.9v-25.1h3.9v2.1c1.2-1.6,3-2.6,5.5-2.6
			C6906.9,2780.8,6910.8,2784.7,6910.8,2790.1z M6896.1,2790.1c0,3.2,2.2,5.7,5.3,5.7c3.3,0,5.3-2.6,5.3-5.7c0-3.1-2-5.7-5.3-5.7
			C6898.3,2784.5,6896.1,2786.9,6896.1,2790.1z"/>
		<path class="st2" d="M6931.5,2790.1c0,0.5,0,1-0.1,1.4h-13.1c0.5,3.2,2.8,4.5,5.3,4.5c1.8,0,3.6-0.7,5.1-1.9l1.9,2.8
			c-2.2,1.8-4.6,2.5-7.3,2.5c-5.3,0-9.1-3.7-9.1-9.3c0-5.5,3.6-9.3,8.8-9.3C6928.1,2780.8,6931.5,2784.6,6931.5,2790.1z
			 M6918.4,2788.6h9.2c-0.4-2.8-2-4.4-4.5-4.4C6920.3,2784.3,6918.7,2786,6918.4,2788.6z"/>
		<path class="st2" d="M6952.2,2790.1c0,0.5,0,1-0.1,1.4h-13.1c0.5,3.2,2.8,4.5,5.3,4.5c1.8,0,3.6-0.7,5.1-1.9l1.9,2.8
			c-2.2,1.8-4.6,2.5-7.3,2.5c-5.3,0-9.1-3.7-9.1-9.3c0-5.5,3.6-9.3,8.8-9.3C6948.8,2780.8,6952.2,2784.6,6952.2,2790.1z
			 M6939.1,2788.6h9.2c-0.4-2.8-2-4.4-4.5-4.4C6941,2784.3,6939.5,2786,6939.1,2788.6z"/>
		<path class="st2" d="M6969,2781.3l-0.9,3.8c-0.8-0.4-2-0.6-2.9-0.6c-2.5,0-4.2,1.7-4.2,4.8v9.7h-3.9v-17.7h3.8v2
			c1.2-1.7,3-2.5,5.1-2.5C6967.2,2780.8,6968.1,2781,6969,2781.3z"/>
		<path class="st2" d="M6986.8,2783.7l-2.5,2.6c-1.3-1.2-2.6-1.8-4.3-1.8c-3,0-5.2,2.3-5.2,5.7s2.3,5.7,5.2,5.7
			c1.7,0,3.2-0.8,4.3-1.8l2.4,2.7c-1.6,1.9-4,2.8-6.6,2.8c-5.6,0-9.3-4-9.3-9.3s3.7-9.3,9.3-9.3
			C6982.9,2780.8,6985.3,2781.8,6986.8,2783.7z"/>
		<path class="st2" d="M7008.6,2790.1c0,5.3-4.1,9.3-9.5,9.3s-9.5-4-9.5-9.3s4.1-9.3,9.5-9.3S7008.6,2784.8,7008.6,2790.1z
			 M6993.5,2790.1c0,3.3,2.4,5.7,5.5,5.7c3.2,0,5.6-2.3,5.6-5.7s-2.4-5.7-5.6-5.7C6995.9,2784.5,6993.5,2786.8,6993.5,2790.1z"/>
		<path class="st2" d="M7018,2775.2c0,1.6-1.2,2.7-2.7,2.7c-1.6,0-2.7-1.1-2.7-2.7c0-1.5,1.1-2.7,2.7-2.7
			C7016.8,2772.4,7018,2773.6,7018,2775.2z M7013.4,2799v-17.7h3.9v17.7H7013.4z"/>
		<path class="st2" d="M7039.8,2787.9v11.1h-3.9v-9.9c0-3-1.5-4.6-4.1-4.6c-2.5,0-4.6,1.6-4.6,4.7v9.8h-3.9v-17.7h3.8v2.2
			c1.4-2.1,3.6-2.6,5.4-2.6C7036.8,2780.8,7039.8,2783.7,7039.8,2787.9z"/>
	</g>
</g>
</svg> -->
<img src="/img/logo.svg" class="icon logo">
{{/if}}

{{#if name === 'share'}}
<!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="2992 376.055 17.163 18.945" id="icon-share">
  <g id="Icon_1_" transform="translate(2992 376.055)">
    <path id="Path_5" data-name="Path 5" class="cls-1" d="M14.289,13.423a2.6,2.6,0,0,0-1.863.715L5.59,10.188a3.105,3.105,0,0,0,.094-.666,3.414,3.414,0,0,0-.094-.666l6.743-3.905a2.881,2.881,0,0,0,4.83-2.1,2.871,2.871,0,0,0-5.742,0,3.414,3.414,0,0,0,.094.666L4.781,7.426a2.866,2.866,0,0,0-1.962-.76A2.824,2.824,0,0,0,0,9.522a2.881,2.881,0,0,0,4.83,2.1l6.787,3.95a2.35,2.35,0,0,0-.094.617,2.77,2.77,0,1,0,2.766-2.761Z"/>
  </g>
</svg> -->
<img src="/img/share.svg" class="icon" width="16">
{{/if}}